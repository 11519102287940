<template>
  <div id="BIPlatform">
    <!-- BIPlatform首页头部 -->
    <div class="BIPlatformHeader">
      <!-- 头部组件 -->
      <Header></Header>
    </div>
    <!-- BICenter中间内容 -->
    <div class="BICenter">
      <div class="body">
        <div style="margin-right: 40px;"><img src="~assets/img/AboutUs/company.png" style=" width: 546px;
        height: 428px;" />
        </div>
        <div>
          <div class="title">象来科技（舟山群岛新区）有限公司</div>
          <div class="detailFont">
            象来科技（舟山群岛新区）有限公司成立于2020年3月，为岱山县招商引资重点项目，公司位于舟山市岱山县岱山数字大楼，实到注册资本1000万元。公司已被舟山市经信局认定为舟山市工业信息工程服务机构，入选2020年舟山市“5313”领军人才项目。象来科技以“挖掘看不见世界中的价值”为使命，通过信息技术、大数据驱动为工业转型升级赋能。
          </div>
          <div style="margin-top: 50px;" class="detailFont">
            公司面向船舶行业企业提供数字化工厂系统解决方案，帮助企业从客户下单到产品研发设计、原材料采购、生产交付等整个公司运营流程全部实现数字化，将作业控制层、现场管理层、企业营运层、协同商务层整合成一个数字化企业圈，并逐步实现全价值链精益化。面向产业集群，通过各类机器设备、人、业务系统的互联，促进数据跨系统、端到端的流动，基于数据分析、建模、利用，实现数据驱动的生产和运营闭环优化，形成新的业务模式和新的业态，向整个行业赋能。
          </div>
        </div>

      </div>
      <div class="active">
        <div style="font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;">公司活动</div>
        <div class="line">
          <img src="~assets/img/AboutUs/阿里云SupET创新中心一周年大会@2x.png" style=" width: 431px;
              height: 243px;" mode="aspectFit"/>
          <img src="~assets/img/AboutUs/公司党支部党建活动@2x.png" style=" width: 364px;
              height: 243px;"/>
          <img src="~assets/img/AboutUs/大会议室@2x.png" style=" width: 364px;
              height: 243px;"/>
        </div>
      </div>
      <div class="honor">
        <div style="text-align: center;margin-bottom: 30px;font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;">公司资质荣誉</div>
        <div style="display: flex; flex-wrap: wrap;justify-content: space-between;">
          <div v-for="(item, index) in honor" :key="index">
            <div class="eachHonor">
              <div class="icon"></div>
              <div>{{ item }}</div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop><img src="~assets/img/BIPlatform/icon-一键置顶@2x.png" alt="icon-回到顶部@2x.png" /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/dsHeader";
import Footer from "components/Footer/dsFooter";
export default {
  name: "ForUs",
  data() {
    return {
      honor: [
        '浙江省科技型中小企业证书',
        '2020年舟山市“5313”行动计划科技创业领军人才项目',
        '2020年舟山市工业互联网平台示范项目',
        '2020年舟山市中小微企业创新创业大赛优胜奖',
        '2020年舟山市智能制造工程服务机构（第三批）',
        '2020年省级工业互联网平台创建名单',
        '2021年浙江省新智造公共服务应用第一批入库供应商',
        '2023年国家科技型中小企业',
        '2023年省级产业数字化服务商名单',
        '2023年入选舟山市民营企业产业数字化服务商',
        '入库浙江省第一批中小企业专业化服务机构名单'],
      proFeaRightContent: [
        { texts: "用问题驱动分析" },
        { texts: "多数据来源支持" },
        { texts: "丰富的图表重量" }
      ],
      currentIndex: 0,
    };
  },
  components: {
    Header,
    Footer
  },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
  methods: {
    changeCon(n) {
      this.currentIndex = n;
      console.log(this.currentIndex);
    }
  }
};
</script>
<style lang="less">
#BIPlatform {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // BIPlatformHeader头部样式
  .BIPlatformHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/AboutUs/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;

    // margin: 0 auto;
    .texts {
      margin-left: 140px;

      .text1 {
        width: 221px;
        height: 38px;
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 25px;
        color: #1e90ff;
        opacity: 1;
        margin-top: 151px;
      }

      .text2 {
        width: 100px;
        height: 10px;
        background: #1e90ff;
        opacity: 1;
        border-radius: 5px;
        margin-top: 11px;
      }

      .text3 {
        width: 489px;
        height: 143px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        color: rgba(51, 51, 51, 0.65);
        opacity: 1;
        margin-top: 20px;
      }
    }
  }

  // BICenter中间内容
  .BICenter {
    margin: 80px auto 79px;
width: 80%;
    .body {
      display: flex;

      .title {
        margin-bottom: 10px;
        height: 30px;
        font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          margin-top: 46px;
      }
      .detailFont{
        font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
      }
    }
  }

  .active {
    margin: 80px auto 79px;
    background-color: rgba(226, 228, 239, 0.3);
    padding: 10px;

    .line {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  .honor {
    margin: 80px auto 79px;
    // text-align: center;
    font-size: 18px;

    .eachHonor {
      display: flex;
      width:400px;
      font-size: 16px;
          font-family: Source Han Sans CN;
          color: #333333;
      .icon {
        background-color: #2796fd;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-bottom: 20px;
        margin-right: 10px;
      }
    }

  }
}

.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
  